<template>
<div class="card-box-full">
  <div class="card-box-header">
    Service Accounts
  </div>


  <!-- ----------------------------------------------------------------- -->
  <div v-if="errorMessage">
    <div class="alert alert-danger" style="margin-top: 15px; margin-bottom: 5px" role="alert">{{ errorMessage }}</div>
  </div>

  <!-- ----------------------------------------------------------------- -->
  <div v-if="isRefreshing">
    <div class="spinner-border spinner-border-sm text-primary" role="status" style="margin: 13px 0px 0px 10px">
      <span class="sr-only"></span>
    </div>
  </div>

  <!-- ----------------------------------------------------------------- -->
  <div v-if="accounts.length > 0">

    <CTabs variant="tabs" :active-tab="0" style="margin-top: 15px">
      <CTab title="Active">
        <div class="tab-box" style="padding: 10px">

          <table class="table table-responsive-sm" style="margin-top: 10px; margin-bottom: 0px;">
            <thead>
              <tr style="font-size: 13px; border: 2px solid transparent">
                <th></th>
                <th style="text-align:left">Name</th>
                <th style="text-align:left">Created</th>
                <th style="text-align:left">Last Seen</th>
              </tr>
            </thead>
            <tbody>
              <tr v-for="account in accounts" style="font-size: 13px">
                <td>
                  <div v-if="account.picture">
                    <img :src="account.picture" class="account-picture" />
                  </div>
                  <div v-else class="account-picture"></div>
                </td>
                <td>{{ account.name }}</td>
                <td>
                  <timeago :datetime="account.created"></timeago>
                </td>
                <td>
                  <timeago :datetime="account.last_seen"></timeago>
                </td>
              </tr>
            </tbody>
          </table>

        </div>
      </CTab>
    </CTabs>
  </div>

</div>
</template>

<style>
.account-picture {
  width: 30px;
  height: 30px;
  border-radius: 50%;
  background-color: #c5c9c9;
}
</style>

<script>
export default {
  name: 'User-Accounts',
  data() {
    return {
      errorMessage: null,
      errorMessageAccount: null,
      isRefreshing: false,
      accounts: []
    }
  },
  methods: {
    //--------------------------------------------------------------------------
    parseMessage(body) {
      try {
        var result = JSON.parse(body);
        return result.message;
      } catch (err) {
        return "";
      }
    },
    //--------------------------------------------------------------------------
    processAccounts(response) {

      // Make sure we have data
      if (!response || !response.accounts) {
        this.errorMessage = "No accounts were returned";
        this.accounts = [];
        return;
      }

      this.accounts = response.accounts;
    },
    //--------------------------------------------------------------------------
    refresh: function() {
      // Make sure we're not currently refreshing
      if (this.isRefreshing) {
        return;
      } else {
        this.errorMessage = null;
        this.isRefreshing = true;
      }

      // Fetch the accounts data
      var that = this;
      var method = "GET";
      var url = "https://auth.platform.robocalls.ai/accounts/services";
      var requiresSession = true;
      RobocallsAI.shared().execute(method, url, null, requiresSession, {
        onSuccess: function(code, body) {
          // Code = HTTP status code
          // Body = Response from server
          that.isRefreshing = false;
          if (code != 200) {
            // Something went wrong
            var message = that.parseMessage(body);
            if (message) {
              that.errorMessage = message;
            } else {
              that.errorMessage = "Received a code " + code + " from the service";
            }
          } else {
            // Parse the accounts
            try {
              var result = JSON.parse(body);
              that.processAccounts(result);
            } catch (err) {
              that.errorMessage = "Failed to parse response from the server";
            }
          }
        },
        onCancelled: function() {
          // Fired if the request was cancelled
          that.isRefreshing = false;
        },
        onFailure: function(error) {
          // Fired if the request fails
          that.isRefreshing = false;
          that.errorMessage = error;
        }
      })
    }
  },
  mounted() {
    this.refresh();
  },
}
</script>
